import React, { useState } from 'react';
import { BtnGroupContainer, IconWrapper } from './style';
import { IconType } from 'react-icons';

/**
 * Componente de RadioButton com suporte a ícones.
 *
 * @param {InputRadioBoxProps} props - Propriedades do botão. Variante de estilo do botão.
 * @param {ButtonOption} props - Propriedades do array de objetos dentro do input. Nela poderá customizar cada option do InputRadio.
 * @param {options} props - Carrega o array [
 *  value: valor da opção,
 *  label: Titulo da opção,
 *  id: poderá passar um identificador unico,
 *  style: onde poderá customizar cada opção separadamente,
 *  icon: caso queira adicionar um icone
 * ]
 * @param {defaultValue} - Quando passada essa prop poderemos escolher qual opção virá selecionada por padrão, a partir do value, caso não passamos ela,
 *  nenhuma opção virá selecionada.
 * @param {qtdButtons} - passa a quantidade de 'radios' que o input terá.
 * @param {style} - Prop para que possa passar alguma estilização para o componente.
 * @param {iconPosition} [props.iconPosition='left'] - Posição do ícone no botão, por default será left.
 */

interface ButtonOption {
  value: string | number;
  label: string;
  id: string;
  style?: React.CSSProperties;
  icon?: IconType;
}

interface InputRadioBoxProps {
  name: string;
  qtdButtons?: number;
  options: ButtonOption[];
  onChange?: (value: string | number) => void;
  style?: React.CSSProperties;
  iconPosition?: 'left' | 'right';
  defaultValue?: string | number;
}

export const InputRadioBox: React.FC<InputRadioBoxProps> = ({
  name,
  qtdButtons = 1,
  options,
  onChange,
  style,
  iconPosition = 'left',
  defaultValue = null,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    defaultValue !== null ? defaultValue : null,
  );

  const handleChange = (value: string | number) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <BtnGroupContainer
      aria-labelledby="radio-button"
      aria-describedby="radio-button"
      style={style}
    >
      {options.slice(0, qtdButtons).map((option, index) => {
        const Icon = option.icon;
        return (
          <React.Fragment key={option.id}>
            <input
              type="radio"
              name={name}
              style={option.style}
              value={option.value}
              id={option.id}
              checked={selectedValue === option.value}
              onChange={() => handleChange(option.value)}
            />
            <label
              style={option.style}
              htmlFor={option.id}
              className={
                index === 0 ? 'first' : index === qtdButtons - 1 ? 'last' : ''
              }
            >
              {Icon && iconPosition === 'left' && (
                <IconWrapper position={iconPosition}>
                  <Icon size={15} aria-hidden="true" />
                </IconWrapper>
              )}
              {option.label}
              {Icon && iconPosition === 'right' && (
                <IconWrapper position={iconPosition}>
                  <Icon size={15} aria-hidden="true" />
                </IconWrapper>
              )}
            </label>
          </React.Fragment>
        );
      })}
    </BtnGroupContainer>
  );
};
