import styled from 'styled-components';

export const BtnGroupContainer = styled.fieldset`
  display: flex;
  align-items: center;
  z-index: 0;
  margin-top: 0.5rem;
  font-family: 'Jost', sans-serif !important;
  font-weight: 500;
  font-size: 16px;

  input[type='radio'] {
    display: none;
  }

  label {
    height: 37px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    transition: background 0.3s, color 0.3s;
  }

  .first {
    border-radius: 4px 0px 0px 4px;
  }

  .last {
    border-radius: 0px 4px 4px 0px;
  }

  input[type='radio']:checked + label {
    background-color: #57069e;
    color: #fff;
    border: 1px solid #57069e;
  }

  label:hover {
    background-color: #8850bf;
    color: #fff;
  }
`;

export const IconWrapper = styled.span<{ position: 'left' | 'right' }>`
  margin-right: ${(props) => (props.position === 'left' ? '0.5rem' : 0)};
  margin-left: ${(props) => (props.position === 'right' ? '0.5rem' : 0)};

  background-color: transparent !important;
`;
